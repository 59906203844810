<template>
  <div>
    <div class="d-flex align-items-center">
      <a-radio-group v-model="isRu">
        <a-radio :style="radioStyle" :value="false">
          Украинская версия
        </a-radio>
        <a-radio :style="radioStyle" :value="true">
          Русская версия
        </a-radio>
      </a-radio-group>
    </div>
    <div v-html="getPage">
  </div>
  </div>
</template>

<script>
export default {
  name: 'id',
  data() {
    return {
      page: {},
      isRu: false,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
    }
  },
  computed: {
    getPage() {
      let page = ''
      if (this.page.translations && this.page.translations.length) {
        const item = this.isRu ? this.page.translations.find((item) => item.locale === 'ru') : this.page.translations.find((item) => item.locale === 'ua')
        page = item.description
      }
      return page
    },
  },
  created() {
    this.getPageData()
  },
  methods: {
    async getPageData() {
      try {
        this.page = (await this.$services.get(`admin/content_pages/${this.$route.params.id}`)).data.data.value
        console.log(this.page)
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style scoped>

</style>
